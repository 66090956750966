<template>
    <div class="Client">
      <!-- BEGIN: Content-->
      <div class="app-content content">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
          <banner
            :title="`${category}`"
            :breadcrumb="[
              {
                label: 'Category ',
              },
              { label: `${serviceName}` },
            ]"
          >
          </banner>
          <div class="row" style="margin-top: -45px">
            <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="card">
                <div class="card-body">
                <div  style=" padding: 5px 5px 5px 5px">
                <div class="row">
  <div class="col-12">
    <h4><strong style="color:#00364f">Step-1 : Source Of Income </strong></h4>
                  <!-- <div class="progress progress-bar-danger">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="40"
                      aria-valuemin="40"
                      aria-valuemax="100"
                      style="background-color: #f21300; width: 20%"
                    >
                      40%
                    </div>
                  </div> -->
                  <!-- <h4 class="text p-1">Select Sources of Income</h4> -->
  </div>
                </div>
                </div>
                <form @submit.prevent="getIncomeRecord">
  
                  <div  style=" padding: 5px 5px 5px 5px">
                <div
                    class="row inner-card"
                    v-for="(modInfo, index) in modulesInfo"
                    :key="index"
                  >
                    <div
                      class="col-11"
                      style="padding-left: 10px"
                      v-if="modInfo.service_module"
                    >
                      <h5 class="heading-red">
                        {{ index + 1 }}. {{ modInfo.service_module.moduleName }}
                      </h5>
                      <small>{{ modInfo.service_module.remark }}</small>
                    </div>
                    <div class="col-1">
                      <div
                        class="form-check form-check-inline form-check-danger text-center"
                      >
                        <input
                          @click="getChargesHere($event, modInfo)"
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                        />
                      </div>
                    </div>
                  </div>
              </div>
  
  
  
  
  
                  <!-- <div class="row">
                    <div class="col-xl-6 col-lg-6 col-12" style=" padding: 5px 5px 5px 5px">
                      <select
                      @change="passState('state')"
                      required
                        v-model="form.state_id"
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option
                          v-for="(state, index) in states"
                          :key="index"
                          :value="state.id"
                        >
                          {{ state.name }}
                        </option>
                      </select>
  
                    </div>
                    <div class="col-xl-6 col-lg-6 col-12 " style=" padding: 5px 5px 5px 5px"> 
                      <select
                      required
  
                      @change="passState('authrizationCapital')"
                        v-model="form.authrizationCapital"
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option value="1"> 1 Lakh</option>
                        <option value="2"> 2 Lakh</option>
                        <option value="3"> 3 Lakh</option>
                        <option value="4"> 4 Lakh</option>
                         <option value="5"> 5 Lakh</option>
                          <option value="6"> 6 Lakh</option>
                          <option value="7"> 7 Lakh</option>
                          <option value="8"> 8 Lakh</option>
                          <option value="9"> 9 Lakh</option>
                          <option value="10"> 10 Lakh</option>
                          <option value="11"> 11 Lakh</option>
                          <option value="12"> 12 Lakh</option>
                          <option value="13"> 13 Lakh</option>
                          <option value="14"> 14 Lakh</option>
                          <option value="15"> 15 Lakh</option>
                          
                      </select>
  
                    </div>
                  </div>
   -->
  
                  <div class="row"  style=" padding: 5px 5px 5px 5px">
                    <div class="col-6 text-start">
                      <router-link to="/retailer/all-services">
                        <button
                          type="button"
                          @click="redirectBack"
                          class="btn btn-sm text-white"
                          style="background-color: #00364f; width: 70%"
                        >
                          <strong>BACK</strong>
                        </button>
                      </router-link>
                    </div>
                    <div class="col-6 text-end">
                      <button
                        type="submit"
                        class="btn btn-sm text-white"
                        style="background-color: #f21300; width: 70%"
                      >
                        <strong>CONTINUE</strong>
                      </button>
  
                      <!-- </router-link> -->
                    </div>
                  </div>
  
                </form>
           
  
  
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px" v-if="faqsRecords.length != 0">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <h2 class="heading">FAQ's</h2>
                      <div
                        class="accordion accordion-margin accordion-border"
                        id="accordionMargin"
                      >
                        <div
                          class="accordion-item"
                          v-for="(faq, index) in faqsRecords"
                          :key="index"
                        >
                      
                          <h2 class="accordion-header" id="headingMarginOne">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="`#faq${index}`"
                              aria-expanded="false"
                              aria-controls="accordionMarginOne"
                            >
                              <i data-feather="plus" class="accordian-plus"></i>
                              {{ faq.title }}
                            </button>
                          </h2>
                          <div
                            :id="`faq${index}`"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingMarginOne"
                            data-bs-parent="#accordionMargin"
                          >
                            <div class="accordion-body">
                              <div class="row">
                              
                                <div class="col-12">{{ faq.description }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <h2 class="heading">Videos</h2>
  
                      <div
                        class="video-player"
                        id="plyr-video-player"
                        style="padding: 5px 5px 5px 5px"
                      >
                        <div>
                          <!-- <div
                            class="scrollmenu"
                            v-for="(faq, index) in faqsRecords"
                            :key="index"
                          >
                            <a href="#" style="width: 100%" v-html="faq.embedVideo"></a>
                          </div> -->
  
                          <div class="scrollmenu">
                            <a href="#home" v-for="(vid, index) in videos" :key="index"
                              ><div style="width: 100%" v-html="vid"></div
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              <strong style="color: #00364f">Service Charges</strong>
            </h5>
            <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
          </div>
          <div class="modal-body">
            <p>
              Total Service Charge :
              <strong style="color: #00364f">{{
                charges.serviceCharge + charges.serviceChargeGst
              }}</strong>
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-sm text-white"
              style="background-color: #00364f"
            >
              CLOSE
            </button>
            <button
              @click="goToCustomerDetails(charges.serviceCharge + charges.serviceChargeGst)"
              class="btn btn-sm text-white"
              style="background-color: #f21300"
            >
              CONTINUE
            </button>
            <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Accept</button> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading == true">
      <Spinner />
    </div>
  </template>
  
  <script>
  import Banner from "../../../../components/retailer/comman/Banner.vue";
  import Spinner from "../../../../components/retailer/comman/Spinner.vue";
  import jQuery from "jquery";
  let $ = jQuery;
  export default {
    name: "IncomeTaxReturnFilling",
    components: {
      Banner,
      Spinner,
    },
    data() {
      return {
        //t termCondition: 'false1'
        loading: false,
        faqVideosRecords: [],
        videos: [],
        faqsRecords: [],
        category: "",
        serviceName: "",
        sourceOfIncome: [],
        charges: {},
        form: {
          serviceModulesIds: [],
          service_id: null,
          state_id : null,
          authrizationCapital:'',
        },
        service_id: null,
        modulesInfo: [],
        states: [],
      };
    },
    methods: {
      passState(type){
  
        var trademark = {};
        trademark = JSON.parse(localStorage.getItem("service"));
      
  
        if(type == 'authrizationCapital'){
          trademark.authrizationCapital = this.form.authrizationCapital
        }
  
        if(type == 'state'){
          trademark.state_id = this.form.state_id
        }
  
  
        localStorage.setItem("service", JSON.stringify(trademark));
      },
      loadStates() {
  
        this.$axios
          .get(`state?all=all`, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            console.log(res.data);
            this.states = res.data.data;
          });
      },
      getVidoe(){
            for(let i  =  0; i < this.faqsRecords.length; i++ ){
              this.videos.push(this.faqsRecords[i].embedVideo)
            }
    
      },
      // getFaqsVideosRecords() {
      //   var data = JSON.parse(localStorage.getItem("service"));
      //   this.service_id = data.service_id;
      //   this.$axios
      //     .get(`retailer/learningcenter?service_id=${this.service_id}`, {
      //       headers: { Authorization: "Bearer " + localStorage.accessToken },
      //     })
      //     .then((res) => {
      //       // console.log(res.data);
      //       res.data;
      //       this.faqVideosRecords = res.data.data.data;
      //     });
      // },
      getFaqsRecords() {
        var data = JSON.parse(localStorage.getItem("service"));
        this.service_id = data.service_id;
        console.log(this.service_id);
        this.loading = true;
        this.$axios
          .get(`retailer/servicefaq?service_id=${this.service_id}`, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            // console.log(res.data);
            res.data;
            this.faqsRecords = res.data.data.data;
          this.getVidoe()
          })
          .finally(() => (this.loading = false));
      },
      goToCustomerDetails(amt) {
        var trademark = {};
        trademark.serviceCharge = "";
        trademark = JSON.parse(localStorage.getItem("service"));
        trademark.serviceCharge = amt;
        localStorage.setItem("service", JSON.stringify(trademark));
        $("#exampleModalCenter").modal("hide");
        this.$router.push({ name: "MyService2" });
      },
  
      getChargesHere(event, value) {
        var itr = {};
        this.form.service_id = value.service_id;
        console.log(event.target.checked);
        if (event.target.checked == true) {
          this.form.serviceModulesIds.push(value.service_module.id);
          console.log(this.form.serviceModulesIds);
          itr.serviceModulesIds = [];
          itr = JSON.parse(localStorage.getItem("service"));
          itr.serviceModulesIds = this.form.serviceModulesIds;
          localStorage.setItem("service", JSON.stringify(itr));
        } else if (event.target.checked == false) {
          this.form.serviceModulesIds.splice(
            this.form.serviceModulesIds.indexOf(value.service_module.id),
            1
          );
          console.log(this.form.serviceModulesIds);
          itr.serviceModulesIds = [];
          itr = JSON.parse(localStorage.getItem("service"));
          itr.serviceModulesIds = this.form.serviceModulesIds;
          localStorage.setItem("service", JSON.stringify(itr));
        }
      },
      getIncomeRecord() {
        this.loading = true;
        this.$axios
          .post("retailer/service/charge", this.form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            console.log("----------------service chargs ----0");
            this.charges = res.data;
            console.log(this.charges);
            $("#exampleModalCenter").modal("show");
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            console.log(error.response);
            this.$swal.fire({
              confirmButtonColor: "#00364f",
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });
      },
      redirectBack() {
        $("#exampleModalCenter").modal("hide");
        this.$router.push({ name: "AllServices" });
      },
  
      getModule() {
        var trademark = {};
        trademark = JSON.parse(localStorage.getItem("service"));
        this.serviceName = trademark.serviceName;
        this.category = trademark.categoryName;
  this.form.service_id = trademark.service_id
        this.$axios
          .get(
            `retailer/servicemodule/charge?service_id=${trademark.service_id}&type=module`,
            {
              headers: { Authorization: "Bearer " + localStorage.accessToken },
            }
          )
          .then((res) => {
           
            if(res.data.data.total > 0){
                localStorage.setItem("modules-api-default", 'false');
            }else{
                localStorage.setItem("modules-api-default", 'true');
            }
        
            localStorage.setItem("back-url", "income-tax-return-filling");
            this.modulesInfo = res.data.data.data;
            var modules = [];
            for (var i = 0; i < this.modulesInfo.length; i++) {
              if (this.modulesInfo[i].service_module != null) {
                modules.push(this.modulesInfo[i].service_module);
              }
            }
  
            if (modules.length < 1) {
              localStorage.setItem("back-url", "all-services");
              trademark.serviceCharge = 0;
              trademark.stepNo = 1;
              localStorage.setItem("service", JSON.stringify(trademark));
              this.$router.push({ name: "MyService2" });
            }
          });
      },
    },
    created() {
      if (
        this.$route.meta.redirectIfLoggedIn == "true" &&
        localStorage.getItem("accessToken") == null
      ) {
        this.$router.push({ name: "Login" });
      } else {
        this.getModule();
        this.getFaqsRecords();
        this.loadStates();
      }
    },
  };
  </script>
  
  <style scoped>
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
  .accordian-plus {
    width: 20px;
    height: 20px;
    stroke: red;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    font-family: "Poppins", sans-serif;
  }
  
  .p-text {
    color: #fff;
    /* font-family: poppins, Sans-serif; */
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  .videoSHoe {
    padding: 5px 5px 10px 5px;
  }
  .bg-image {
    background-color: #fff;
    background-image: url(https://apk.mudhrape.com/wp-content/uploads/2022/01/background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 0 120px;
    /* text-align: center; */
    color: white;
    /* background-size: 100% 100%;
     width: 100%; */
    font-family: "Poppins", sans-serif;
  }
  
  .h2-heading {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
  }
  
  @media (max-width: 767px) {
    .h2-heading {
      font-size: 22px;
      font-family: "Poppins", sans-serif;
    }
  }
  
  .heading {
    color: #00364f;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 20px 0;
  }
  
  .inner-card {
    box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px 5px 5px 5px;
    padding: 10px;
  }
  
  .heading-red {
    color: #f21300;
    font-family: "Poppins", sans-serif;
    /* font-size: 20px; */
    font-weight: 600;
  }
  
  div.scrollmenu {
    background-color: #ffff;
    overflow: auto;
    white-space: nowrap;
  }
  
  div.scrollmenu a {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 3px 3px 3px 3px;
  
    text-decoration: none;
  }
  </style>
  